<template>
  <div>
      <user-project-process></user-project-process>
  </div>
</template>

<script>
import {
  default as UserProjectProcess
} from '@/wam/components/widgets/UserProjectProcess';
import { mapGetters } from 'vuex';
export default {
  name: 'user_project_dataset_filter',
  computed: mapGetters({}),
  components: {
    UserProjectProcess,
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
  },
  watch: {}
}

</script>

